import { useEffect, useCallback } from 'react'

const useKeyDown = ( keys, listener, deps = [], skip = false ) => {
  const internalListener = useCallback( keyDownEvent => {
    if ( skip ) {
      return
    }

    const { key } = keyDownEvent
    const listenForKeys = Array.isArray( keys ) ? keys : [keys]

    if ( listenForKeys.includes( key ) ) {
      listener( keyDownEvent )
    }
  }, [keys, listener, skip] )

  useEffect( () => {
    if ( typeof window !== 'undefined' ) {
      window.addEventListener( 'keydown', internalListener, true )
      return () => {
        window.removeEventListener( 'keydown', internalListener, true )
      }
    }
  }, [internalListener, ...deps] )
}

export default useKeyDown
