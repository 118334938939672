import {
  createContext, useContext, useMemo, useState, 
} from 'react'
import request from './request'
import GLOBALS_QUERY from '../../graphql/globals'

const CraftContext = createContext( null )
CraftContext.displayName = 'CraftContext'

export const useCraft = () => useContext( CraftContext )

export function CraftProvider( { pageProps, children } ) {
  const [clientSideRequestProps, setClientSideRequestProps] = useState( {} )

  const clientSideRequest = ( query = null, variables = {} ) => {
    getCraftData( query, variables )
      .then( ( { props } ) => setClientSideRequestProps( props ) )
  }

  const value = useMemo( () => ( {
    pageProps: { ...pageProps, ...clientSideRequestProps },
    clientSideRequest,
  } ), [pageProps, clientSideRequestProps] )

  return (
    <CraftContext.Provider value={ value }>
      { children }
    </CraftContext.Provider>
  )
}

export const getServerSideCraftData = ( query, variables = {} ) => async () => {
  const [queryResponse, globalsResponse] = await Promise.all( [
    request( query, variables ),
    request( GLOBALS_QUERY ),
  ] )
  
  return {
    props: { ...queryResponse.data, ...globalsResponse.data },
  }
}

export const getCraftData = ( query = null, variables = {} ) => {
  const requests = [request( GLOBALS_QUERY )]

  if ( query ) {
    requests.push( request( query, variables ) )
  }

  return Promise.all( [
    request( query, variables ),
    request( GLOBALS_QUERY ),
  ] )
    .then( ( [queryResponse, globalsResponse] ) => ( {
      props: { ...queryResponse.data, ...globalsResponse.data },
    } ) )
}
