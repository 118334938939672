import { css } from 'styled-components'

const textLink = css`
  color: inherit;
  text-decoration: solid underline ${ p => p.theme.colors.black } 1px;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
`

export const textLinkWithHover = css`
  ${ textLink }
  transition: background-color .3s ease-out;

  &:hover {
    background-color: ${ p => p.theme.colors.orangeShade };
  }
`

export const soloTextLink = css`
  ${ p => p.theme.typo.switzerSemiBold }
  font-size: ${ p => p.theme.typo.sizes.dropped };
  color: inherit;
  text-decoration-color: ${ p => p.theme.colors.orangeShade };
  text-underline-offset: 3px;
`

export default textLink
