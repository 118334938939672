const IMAGE_FRAGMENT = `
  url
  alt
  width
  height
  focalPoint
  dateModified
`

export default IMAGE_FRAGMENT
