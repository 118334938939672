import styled, { css } from 'styled-components'

function Chevron( {
  color = 'black', 
  left = false, 
  right = false, 
  up = false, 
  down = false, 
} ) {
  return (
    <StyledChevron
      $left={ left }
      $right={ right }
      $up={ up }
      $down={ down }
      $color={ color }
    >
      <svg 
        width="10"
        height="18"
        viewBox="0 0 10 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          d="M8.98975 17L0.979337 8.98959L8.98974 0.979183"
          stroke="black"
        />
      </svg>

    </StyledChevron>
  )
}

interface StyledChevron {
  $left?: boolean,
  $right?: boolean,
  $up?: boolean,
  $down?: boolean,
  $color?: string,
}

const StyledChevron = styled.div<StyledChevron>`
  svg {
    display: block;

    ${ p => p.$right && css`
      transform: rotate( 180deg );
    ` }
    
    ${ p => p.$down && css`
      transform: rotate( 270deg );
    ` }
    
    ${ p => p.$up && css`
      transform: rotate( 90deg );
    ` }

    path {
      stroke: ${ p => p.theme.getColor( p.$color ) };
    }
  }
`

export default Chevron
