import styled from 'styled-components'

const Button = styled.div`
  > button,
  > a,
  > span {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1.2rem;
    ${ p => p.theme.typo.switzer }
    font-size: ${ p => p.theme.typo.sizes.dropped };
    line-height: 1.25;
    border: 1px solid ${ p => p.theme.colors.black };
    border-radius: 20rem;
    background: transparent;
    color: ${ p => p.theme.colors.black };
    text-decoration: none;
    cursor: pointer;
    transition: 
      background .3s ease-out,
      color .3s ease-out;

    svg {
      &:first-child {
        margin-right: 0.6rem;
      }

      &:last-child {
        margin-left: 0.6rem;
        margin-right: -0.3rem;
        margin-top: 1px;
      }

      path {
        fill: currentColor;
      }
    }

    &:not( :disabled ):hover,
    a:hover & {
      background: ${ p => p.theme.colors.black };
      color: ${ p => p.theme.colors.ivory };
    }

    &:focus,
    &:focus-visible {
      outline: none;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 20rem;
        box-shadow: 0 0 0 2px ${ p => p.theme.colors.purple };
      }
    }

    &:focus:not( :focus-visible ) {
      &:before {
        display: none;
      }
    }

    &:disabled {
      filter: grayscale( 0.8 );
      opacity: 0.7;
    }
  }
`

export default Button
