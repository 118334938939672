import { rgba } from 'polished'
import styled from 'styled-components'
import Link from '../Link'
import Loader from '../Loader'

function SearchResults( { results, isLoading = false } ) {
  return (
    <>
      <StyledResults>
        { results?.length === 0 && (
        <p>There are no results for your search.</p>
        ) }
        { results?.length > 0 && (
        <>
          <p>{ results.length } result{ results.length !== 1 && 's' }</p>
          <ol>
            { results.map( result => (
              <ResultItem key={ result.id } result={ result } />
            ) )}
          </ol>
        </>
        ) }
      </StyledResults>
      <Loader 
        isLoading={ isLoading }
        delay={ 200 }
        fullscreen
      />
    </>
  )
}

function ResultItem( { result } ) {
  const { title, url, label } = getResultInfo( result )

  if ( !url ) {
    return null
  }

  return (
    <StyledResult>
      <Link href={ url }>
        { title }
        <div>
          { label && <span>{ label }</span> }
        </div>
      </Link>
    </StyledResult>
  )
}

const getFullSessionTitle = session => `Session ${ session.lft / 2 }: ${ session.title }`

const getResultInfo = result => {
  if ( result.sectionHandle === 'sessions' ) {
    return {
      title: result.title,
      url: `/course/${ result.slug }`,
      label: `Session ${ result.lft / 2 }`,
    }
  }
  
  if ( result.sectionHandle === 'chunks' ) {
    return {
      title: result.title,
      url: `/course/chunks/${ result.slug }${ result.relatedSession && `?sessionId=${ result.relatedSession.id }` }`,
      label: result.relatedSession && getFullSessionTitle( result.relatedSession ),
    }
  }

  return {
    title: result.title,
    url: `/${ result.slug }`,
    label: 'Info',
  }
}

const StyledResults = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;

  > * {
    max-width: 40rem;
  }

  > p {
    margin: 0;
    padding: 0 0 2rem 0;
    ${ p => p.theme.typo.switzerSemiBold }
    font-size: ${ p => p.theme.typo.sizes.dropped };
  }

  ol {
    margin: 0;
    padding: 0 0 0 1.5rem;
  }
`

const StyledResult = styled.li`
  padding-bottom: 1rem;
  ${ p => p.theme.typo.switzerSemiBold }
  font-size: ${ p => p.theme.typo.sizes.normal };

  a {
    color: ${ p => p.theme.colors.black };
    text-decoration: none;
  }

  span {
    ${ p => p.theme.typo.switzer }
    font-size: ${ p => p.theme.typo.sizes.dropped };
    color: ${ p => rgba( p.theme.colors.black, 0.6 ) };

    &:nth-child( 2 ) {
      display: inline-block;
      color: ${ p => p.theme.colors.black };
      border-left: 1px solid ${ p => p.theme.colors.black };
      padding-left: 0.5rem;
      margin-left: 0.5rem;
    }
  }
`

export default SearchResults
