import IMAGE_FRAGMENT from './fragments/image'

const GLOBALS_QUERY = `
query GlobalsQuery {
  partnerLogos: globalSet(
    handle: "partnerLogos"
  ) {
    ...on partnerLogos_GlobalSet {
      partnerLogos {
        ...on partnerLogos_logo_BlockType {
          id
          logo {
            ${ IMAGE_FRAGMENT }
          }
          partnerUrl
        }
      }
    }
  }

  analyticsCookieConsent: globalSet(
    handle: "analyticsCookieConsent"
  ) {
    ...on analyticsCookieConsent_GlobalSet {
      gaTrackingId
      cookieConsent {
        ...on cookieConsent_BlockType {
          ccTitle
          ccBodyText
          acceptButtonText
        }
      }
    }
  }

  sessions: entries(
    section: "sessions"
    orderBy: "lft asc"
  ) {
    ...on sessions_default_Entry {
      id: canonicalId
      title
      slug
      lft
      chunks {
        ...on chunks_default_Entry {
          id: canonicalId
          slug
          title
          chunkType {
            title
          }
        }
      }
    }
  }
}
`

export default GLOBALS_QUERY
