import styled from 'styled-components'

function Logo( { width = 243 } ) {
  return (
    <StyledLogo $width={ width }>
      <span>
        Cities learning <br />
        for fair, clean and <br />
        democratic energy
      </span>
    </StyledLogo>
  )
}

const StyledLogo = styled.div<{ $width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ p => p.$width }px;
  height: ${ p => p.$width }px;
  border-radius: 100%;
  background: ${ p => p.theme.colors.black };

  span {
    display: block;
    width: ${ p => p.$width * 0.7671 }px;
    ${ p => p.theme.typo.sourceSerifPro }
    font-size: ${ p => p.$width * 0.08375 }px;
    line-height: 1.05;
    color: ${ p => p.theme.colors.ivory };
    
    a & {
      text-decoration: none;
    }
  }
`

export default Logo
