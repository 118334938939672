import styled from 'styled-components'
import { useCraft } from '../lib/craft'
import Container from './layout/Container'
import Stack from './layout/Stack'
import Link from './Link'
import Image from './Image'
import { navItems } from './Nav'
import { fancyLinkReversed, fancyLinkReversedHover } from '../styles/fancyLink'

function Footer() {
  const craftData = useCraft()
  const partnerLogos = craftData?.pageProps?.partnerLogos?.partnerLogos

  return (
    <StyledFooter>
      <Container>
        <Stack
          $top={ 3 }
          $bottom={ 3 }
        >
          <StyledFooterNav>
            { navItems.map( navItem => (
              <Link
                key={ navItem.href }
                href={ navItem.href }
              >
                { navItem.text }
              </Link>
            ) ) }
          </StyledFooterNav>
          <StyledFooterLogos>
            { partnerLogos?.map( logo => (
              <div key={ logo.id }>
                <PartnerLogo logo={ logo } />
              </div>
            ) ) }
          </StyledFooterLogos>
          <StyledLegalLinks>
            <Link href="/privacy">
              Privacy policy
            </Link>
          </StyledLegalLinks>
        </Stack>
      </Container>
    </StyledFooter>
  )
}

function PartnerLogo( { logo } ) {
  const image = logo.logo[ 0 ]

  if ( logo.partnerUrl ) {
    return (
      <Link href={ logo.partnerUrl }>
        <Image
          src={ image.url }
          alt={ image.alt }
          width={ image.width }
          height={ image.height }
          dateModified={ image.dateModified }
          layout="raw"
        />
      </Link>
    )
  }

  return (
    <div>
      <Image
        src={ image.url }
        alt={ image.alt }
        width={ image.width }
        height={ image.height }
        dateModified={ image.dateModified }
        layout="raw"
      />
    </div>
  )
}

const StyledFooter = styled.footer`
  position: relative;
  overflow: hidden;
  border-top: 1px solid ${ p => p.theme.colors.black };
  border-bottom: 23px solid ${ p => p.theme.colors.orange };
  background: ${ p => p.theme.colors.ivory };
`

const StyledLegalLinks = styled.div`
  display: flex;
  margin-top: 1rem;

  > a {
    position: relative;
    text-decoration: none;
    font-size: ${ p => p.theme.typo.sizes.dropped };
    color: inherit;
    text-decoration: none;
    ${ fancyLinkReversed }

    &:hover {
      ${ fancyLinkReversedHover }
    }
  }

  @media ( max-width: 480px ) {
    margin-top: 3rem;
    justify-content: center;
  }
`

const StyledFooterNav = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -1rem;

  > a {
    position: relative;
    color: inherit;
    text-decoration: none;
    ${ fancyLinkReversed }

    &:hover {
      ${ fancyLinkReversedHover }
    }
  }

  @media ( max-width: 599px ) {
    margin-bottom: 3rem;

    > * {
      margin: 0.5rem 1rem;
      border-bottom: 1px solid ${ p => p.theme.colors.black };
    }
  }

  @media ( min-width: 600px ) {
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      margin: 1rem;
    }
  }
`

const StyledFooterLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -1rem -1.5rem;

  > * {
    position: relative;
    padding: 1rem 1.5rem;

    > * {
      position: relative;
      display: block;
      max-width: 160px;

      img {
        max-width: 100%;
        max-height: 100px;
        width: auto;
        height: auto;
      }
    }
  }

  @media ( max-width: 480px ) {
    justify-content: center;
  }
`

export default Footer
