import { useState } from 'react'
import styled, { css } from 'styled-components'
import { layoutVariables } from '../styles/layoutVariables'
import Link from './Link'
import Chevron from './icons/Chevron'

function SideNav( {
  session,
  currentChunk,
} ) {
  const [isOpen, setIsOpen] = useState( false )

  return (
    <div>
      <StyledSideNav $isOpen={ isOpen }>
        <button
          type="button"
          onClick={ () => setIsOpen( s => !s ) }
        >
          Session Menu
          <Chevron down />
        </button>
        <div>
          <Link href={ `/course/${ session.slug }` }>
            <span>Session { session.lft / 2 }</span>
            { session.title }
          </Link>
          { session.chunks.map( ( chunk, i ) => {
            if ( currentChunk?.id && chunk.id !== currentChunk?.id ) {
              return (
                <Link 
                  key={ chunk.id }
                  href={ `/course/chunks/${ chunk.slug }?sessionId=${ session.id }` }
                >
                  { chunk.chunkType.length > 0 && (
                    <span>{ i + 1 }. { chunk.chunkType[ 0 ].title }</span> 
                  ) }
                  { !chunk.chunkType?.length && `${ i + 1 }.` }{ chunk.title }
                </Link>
              )
            }

            return (
              <div key={ chunk.id }>
                { chunk.chunkType.length > 0 && (
                <span>{ i + 1 }. { chunk.chunkType[ 0 ].title }</span> 
                ) }
                { !chunk.chunkType?.length && `${ i + 1 }.` }{ chunk.title }
              </div>
            )
          } ) }
        </div>
      </StyledSideNav>
    </div>
  )
}

const StyledSideNav = styled.nav<{ $isOpen: boolean }>`
  max-width: 300px;
  margin-bottom: 2rem;

  > div {
    a,
    div {
      position: relative;
      display: block;
      margin-bottom: 1px;
      color: inherit;
      transition: background .3s ease-out;
      text-decoration-color: transparent;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
      transition: text-decoration-color .3s ease-out;

      span {
        display: block;
        ${ p => p.theme.typo.switzerSemiBold }
      }

      &:first-child {
        padding-bottom: 1rem;
        ${ p => p.theme.typo.sentient }
        font-size: ${ p => p.theme.typo.sizes.slightlyBumped };
        line-height: calc( 1rem * 1.35 );
        
        span {
          ${ p => p.theme.typo.sentientMedium }
        }

        &:hover {
          text-decoration-color: currentColor;
        }
      }

      &:not( :first-child ) {
        padding: 1rem;
        padding-top: 0;
      }
    }

    a:hover {
      text-decoration-color: currentColor;
    }

    div {
      padding-top: 1rem !important;
      background: ${ p => p.theme.colors.orange };
    }

    div + * {
      padding-top: 1rem !important;
    }
  }

  @media ( max-width: ${ layoutVariables.sideNavBreakpoint - 1 }px ) {
    button {
      display: flex;
      width: 100%;
      margin-bottom: 1rem;
      padding: 0.5rem;
      background: ${ p => p.theme.colors.orange };
      border: 0;
      border-bottom: 1px solid ${ p => p.theme.colors.black };
      cursor: pointer;

      > div {
        margin-left: auto;
        margin-right: 0.5rem;
      }
    }

    > div {
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition:
        opacity .3s linear,
        max-height .3s ease-out .3s;

      ${ p => p.$isOpen && css`
        max-height: 3000px;
        opacity: 1;
        transition:
          opacity .3s linear .3s,
          max-height .3s ease-in-out;
      ` }
    }
  }
  
  @media ( min-width: ${ layoutVariables.sideNavBreakpoint }px ) {
    button {
      display: none;
    }
  }
`

export default SideNav
