export type ColorKeys = 
  'black' 
  | 'white'
  | 'ivory'
  | 'warmLightGrey'
  | 'orange'
  | 'purple'

const colors = {
  black: '#1D1D1B',
  white: '#ffffff',
  ivory: '#F5F5EF',
  warmLightGrey: '#EBEBE3',
  orange: '#FCD7C2',
  orangeShade: '#FBC7AA',
  purple: '#D2D1F0',
}

export const getColor = ( color: ColorKeys ) => {
  if ( colors[ color ] ) {
    return colors[ color ]
  }

  return color
}

export default colors
