import styled from 'styled-components'
import Link from './Link'
import Container from './layout/Container'
import Logo from './Logo'
import Nav from './Nav'

function Header() {
  return (
    <Container>
      <StyledHeader>
        <StyledHeaderLogo>
          <Link href="/">
            <Logo />
          </Link>
        </StyledHeaderLogo>
        <StyledHeaderNav>
          <Nav />
        </StyledHeaderNav>
      </StyledHeader>
    </Container>
  )
}

const StyledHeader = styled.header`
  position: relative;
  display: grid;
  grid-template-columns: repeat( 12, calc( 100% / 12 ) );
  margin: 0 calc( var( --grid-gutter ) / -2 );

  > * {
    margin: 0 calc( var( --grid-gutter ) / 2 );
  }
`

const StyledHeaderLogo = styled.div`
  grid-column: 1 / 5;
  position: relative;
  top: -3rem;

  a,
  svg {
    display: block;
    text-decoration: none;
  }

  @media ( max-width: 550px ) {
    margin-left: -5vw;
  }
`

const StyledHeaderNav = styled.div`  
  z-index: 1;
  grid-column: 6 / 13;

  @media ( min-width: 1100px ) {
    grid-column: 5 / 13;
  }
`

export default Header
