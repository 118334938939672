import styled from 'styled-components'
import { textLinkWithHover } from '../styles/textLink'
import ButtonLink from './ButtonLink'
import Button from './Button'
import ContentBlocksImage from './ContentBlocksImage'

function ContentBlocks( { contentBlocks } ) {
  return (
    <StyledContentBlocks>
      { contentBlocks.map( block => block?.id && (
        <ContentBlock
          key={ block.id }
          block={ block }
        />
      ) ) }
    </StyledContentBlocks>
  )
}

function ContentBlock( { block } ) {
  if ( block.typeHandle === 'text' ) {
    return (
      <StyledText dangerouslySetInnerHTML={ { __html: block.text } } />
    )
  }
  
  if ( block.typeHandle === 'image' ) {
    return (
      <ContentBlocksImage image={ block.image[ 0 ] } />
    )
  }

  if ( block.typeHandle === 'button' ) {
    return (
      <ButtonLink href={ block.buttonUrl }>
        { block.buttonText }
      </ButtonLink>
    )
  }
  
  if ( block.typeHandle === 'embeddedContent' ) {
    const embed = block.embed[ 0 ].embeddedAsset
    const aspectRatio = embed.height / embed.width

    return (
      <StyledEmbed 
        $aspectRatio={ aspectRatio }
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={ { __html: embed.html } } 
      />
    )
  }
}

const StyledContentBlocks = styled.div`
  ${ Button } {
    margin-top: 1.3rem;
  }
`

const StyledText = styled.div`
  overflow-wrap: break-word;
  word-wrap: break-word;

  > * {
    margin-bottom: 0;
  }

  &:not( :first-child ) {
    > *:first-child {
      margin-top: 2rem;
    }    
  }

  &:first-child {
    > *:first-child {
      margin-top: 0;
    }    
  }

  h2,
  h3 {
    margin-top: 2rem;
    ${ p => p.theme.typo.sentientMedium }
    line-height: calc( 1rem * 1.35 );
  }

  h2 {
    font-size: ${ p => p.theme.typo.sizes.slightlyBumped };
  }

  h3 + p {
    margin-top: 0;
  }

  p {
    margin-top: 1rem;

    &:first-child {
      margin: 0;
    }
  }

  a {
    ${ textLinkWithHover }
  }

  ul,
  ol {
    padding-left: 1.5rem;
    margin-bottom: 1rem;

    li {
      margin: 0;
      padding: 0;
    }
  }
`

const StyledEmbed = styled.div<{ $aspectRatio: number }>`
  position: relative;
  width: auto;
  padding-bottom: ${ p => p.$aspectRatio * 100 }%;

  &:not( :first-child ) {
    margin-top: 1.5rem;
  }

  iframe,
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

export default ContentBlocks
