import { useCraft } from '../lib/craft'
import { GAProvider } from '../lib/ga'

function MPowerGAProvider( { children } ) {
  const craftData = useCraft()
  const gaTrackingId = craftData?.pageProps?.analyticsCookieConsent?.gaTrackingId

  return (
    <GAProvider gaTrackingId={ gaTrackingId }>
      { children }
    </GAProvider>
  )
}

export default MPowerGAProvider
