import Button from './Button'
import Link from './Link'
import Arrow from './icons/Arrow'

function ButtonLink( { href = null, children } ) {
  return (
    <Button>
      { href && (
        <Link href={ href }>
          { children }
          <Arrow />
        </Link>
      ) }
      { !href && (
        <span>
          { children }
          <Arrow />
        </span>
      ) }
    </Button>
  )
}

export default ButtonLink
