import styled, { css } from 'styled-components'

interface Stack {
  $top?: number | string,
  $bottom?: number | string,
  $between?:
    number | string
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-evenly'
    | 'space-around',
  $height?: string,
  $responsive?: boolean,
}

const betweenFlexValues = [
  'flex-start',
  'flex-end',
  'center',
  'space-between',
  'space-evenly',
  'space-around',
]

const getPaddingValue = ( propValue, isResponsive ) => {
  if ( typeof propValue === 'string' ) {
    return propValue
  }

  if ( isResponsive ) {
    // at <400 spacing is 1x propValue in rem
    // at >800 spacing is 1.5x propValue in rem
    // from 400-800 spacing is ( ( 0.5 * ( 100vw / ( 800 - 400 ) ) ) + 1 )x propValue in rem
    const oneRem = 18
    const range = {
      bottom: {
        vw: 400,
        multiplier: 1,
      },
      top: {
        vw: 800,
        multiplier: 1.5,
      },
    }

    const rangeWidth = range.top.vw - range.bottom.vw
    const multiplierRangeWidth = range.top.multiplier - range.bottom.multiplier
    const multiplier = multiplierRangeWidth / rangeWidth
    const offset = range.bottom.multiplier - ( range.bottom.vw * multiplier )

    return `
      max( 
        ${ range.bottom.multiplier * propValue }rem, 
        min(
          ${ range.top.multiplier * propValue }rem,
          calc( 
            ${ multiplier * oneRem * propValue * 100 }vw 
            + ${ offset * oneRem * propValue }px
          )
        ) 
      )
    `
  }

  return `${ propValue }rem`
}

const Stack = styled.div<Stack>`
  position: relative;

  ${ p => p.$height && css`
    height: ${ p.$height };
  ` }

  ${ p => (
    p.$between
    && typeof p.$between === 'string'
    && betweenFlexValues.includes( p.$between )
  ) && css`
    display: flex;
    flex-direction: column;
    jusify-content: ${ p.$between };
  ` }

  ${ p => (
    p.$between
    && typeof p.$between === 'string'
    && !betweenFlexValues.includes( p.$between )
  ) && css`
    > * {
      padding-bottom: ${ p.$between };

      &:last-child {
        padding-bottom: 0;
      }
    }
  ` }

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${ p => p.$between && typeof p.$between === 'number' && css`
    > * {
      padding-bottom: ${ getPaddingValue( p.$between, p.$responsive ) };

      &:last-child {
        padding-bottom: 0;
      }
    }
  ` }

  ${ p => p.$top && typeof p.$top === 'number' && css`
    padding-top: ${ getPaddingValue( p.$top, p.$responsive ) };
  ` }

  ${ p => p.$top && typeof p.$top === 'string' && css`
    padding-top: ${ p.$top };
  ` }

  ${ p => p.$bottom && typeof p.$bottom === 'number' && css`
    padding-bottom: ${ getPaddingValue( p.$bottom, p.$responsive ) };
  ` }

  ${ p => p.$bottom && typeof p.$bottom === 'string' && css`
    padding-bottom: ${ p.$bottom };
  ` }
`

export default Stack

interface StackItem {
  $top?: number,
  $bottom?: number,
}

export const StackItem = styled.div<StackItem>`
  ${ p => p.$top && css`
    padding-top: ${ p.$top }rem;
  ` }

  ${ p => p.$bottom && css`
    padding-bottom: ${ p.$bottom }rem;
  ` }
`
