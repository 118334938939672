import styled from 'styled-components'
import Image from './Image'

function ContentBlocksImage( { image } ) {
  if ( !image ) {
    return null
  }
  
  return (
    <StyledImage>
      <Image
        src={ image.url }
        alt={ image.alt }
        width={ image.width }
        height={ image.height }
      />
    </StyledImage>
  )
}

const StyledImage = styled.div`

`

export default ContentBlocksImage
