import { useRouter } from 'next/router'
import MetaTags from '../lib/craft/MetaTags'
import { imageUrl } from './Image'
import { useCraft } from '../lib/craft'

const HOSTNAME = process.env.NEXT_PUBLIC_APP_HOSTNAME

function MPowerMetaTags() {
  const router = useRouter()
  const craftData = useCraft()
  const entry = craftData?.pageProps?.entry

  if ( !entry ) {
    return null
  }

  const tags = getTags( entry, router )
  return <MetaTags metaTags={ tags } />
}

const getTags = ( entry, router ) => {
  const { asPath } = router
  const url = `https://${ HOSTNAME }${ asPath }`
  const metaTags = entry?.metaTags?.[ 0 ]
  const title = getTitle( entry )
  const description = metaTags?.metaDescription || entry.pageIntro || ''
  const imgUrl = metaTags?.metaImage[ 0 ]?.url

  const ogImage = imgUrl && imageUrl( {
    src: imgUrl,
    width: 1200,
    height: 630,
  } )
  
  const twitterImage = imgUrl && imageUrl( {
    src: imgUrl,
    width: 1200,
    height: 675,
  } )

  return [
    // Primary Meta Tags
    {
      name: 'title',
      value: title,
    },
    {
      name: 'description',
      value: description,
    },
    // Open Graph / Facebook 
    {
      name: 'og:type',
      value: 'website',
    },
    {
      name: 'og:url',
      value: url,
    },
    {
      name: 'og:title',
      value: title,
    },
    {
      name: 'og:description',
      value: description,
    },
    {
      name: 'og:image',
      value: ogImage,
    },
    // Twitter
    {
      name: 'twitter:card',
      value: twitterImage ? 'summary_large_image' : 'summary',
    },
    {
      name: 'twitter:url',
      value: url,
    },
    {
      name: 'twitter:title',
      value: title,
    },
    {
      name: 'twitter:description',
      value: description,
    },
    {
      name: 'twitter:image',
      value: twitterImage,
    },
  ]
}

const getTitle = entry => {
  const siteTitle = 'mPower Learn'
  const metaTags = entry?.metaTags?.[ 0 ]
  let title
  
  if ( metaTags?.metaTitle ) {
    title = metaTags.metaTitle
  }

  if ( entry.slug === 'homepage' ) {
    title = siteTitle
  }

  title = entry.title || siteTitle

  if ( title.indexOf( siteTitle ) === -1 ) {
    title = `${ title } — ${ siteTitle }`
  }

  return title
}

export default MPowerMetaTags
