import styled from 'styled-components'
import Image from './Image'

function SessionIllustration( { image } ) {
  return (
    <StyledSessionIllustration>
      <Image
        src={ image.url }
        alt={ image.alt }
        width={ image.width }
        height={ image.height }
        dateModified={ image.dateModified }
        layout="fill"
        objectFit="contain"
        sizes="150px"
      />
    </StyledSessionIllustration>
  )
}

const StyledSessionIllustration = styled.div`
  position: relative;
  height: 180px;
  margin-bottom: 2rem !important;
`

export default SessionIllustration
