import { css } from 'styled-components'
import colors from './colors'

const sentient = css`
  font-family: 'Sentient';
  font-weight:  300;
  font-style:   normal;
`

const switzer = css`
  font-family: 'Switzer';
  font-weight:  normal;
  font-style:   normal;
`

const sourceSerifPro = css`
  font-family: 'Source Serif Pro', serif;
  font-weight: 700;
`

export const typo = {
  links: [
    'https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@700&display=swap',
  ],
  sentient,
  sentientLight: sentient,
  sentientMedium: css`
    ${ sentient }
    font-weight: 500;
  `,
  switzer,
  switzerMedium: css`
    ${ switzer }
    font-weight: 500;
  `,
  switzerSemiBold: css`
    ${ switzer }
    font-weight: 600;
  `,
  sourceSerifPro,
  sizes: {
    tiny: '0.777rem', // 14px
    dropped: '0.888rem', // 16px
    normal: '1rem', // 18px
    slightlyBumped: '1.111rem', // 20px
    bumped: '1.222rem', // 22px
    medium: '1.333rem', // 24px
    mediumLarge: '1.666rem', // 30px
    large: '2rem', // 36px
    hero: '2.666rem', // 48px
  },
}

const fonts = css`
  html,
  body,
  input,
  textarea,
  select,
  button {
    ${ typo.switzer }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${ colors.black };
    font-size: 18px;
    line-height: 1.35;

    @media ( max-width: 300px ) {
      font-size: 15px;
    }
  }
`

export default fonts
