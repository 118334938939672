function MpowerShapes() {
  return (
    <svg 
      viewBox="0 0 1886 623"
      preserveAspectRatio="xMinYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M0 373.819V622.843H449.663V373.819L224.835 160.268L0 373.819Z"
        fill="#EAEAE3"
      />
      <path 
        d="M1192.9 136C1144.6 135.999 1097.39 150.254 1057.23 176.962C1017.07 203.669 985.77 241.63 967.286 286.044C948.803 330.458 943.967 379.329 953.389 426.479C962.812 473.628 986.07 516.938 1020.22 550.931C1054.37 584.923 1097.89 608.073 1145.26 617.451C1192.63 626.83 1241.73 622.016 1286.35 603.619C1330.97 585.222 1369.11 554.068 1395.94 514.096C1422.78 474.124 1437.1 427.131 1437.1 379.057C1437.1 347.139 1430.78 315.532 1418.51 286.043C1406.24 256.554 1388.25 229.76 1365.57 207.19C1342.9 184.62 1315.98 166.716 1286.35 154.502C1256.72 142.287 1224.97 136 1192.9 136Z"
        fill="#EAEAE3"
      />
      <path 
        d="M1684.72 131.405C1658.28 131.404 1632.11 136.62 1607.69 146.754C1583.27 156.887 1561.08 171.741 1542.39 190.466C1523.69 209.191 1508.87 231.421 1498.75 255.886C1488.64 280.352 1483.43 306.574 1483.43 333.055V622.843H1886V333.055C1886 306.574 1880.79 280.352 1870.68 255.886C1860.56 231.421 1845.74 209.191 1827.05 190.466C1808.35 171.741 1786.16 156.887 1761.74 146.754C1737.32 136.62 1711.15 131.404 1684.72 131.405"
        fill="#EAEAE3"
      />
      <path 
        d="M902.363 0H499.034V622.843H902.363V0Z"
        fill="#EAEAE3"
      />
    </svg>
  )
}

export default MpowerShapes
