import { css } from 'styled-components'

const layout = css`
  :root {
    --grid-gutter: 32px;
  }
`

export const layoutVariables = {
  sideNavBreakpoint: 1250,
}

export default layout
