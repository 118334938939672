import {
  useState, useRef, createContext, useContext, useMemo, useCallback, useEffect,
} from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import request from '../../lib/craft/request'
import useRouteChange from '../../hooks/useRouteChange'
import useModalTraits from '../../hooks/useModalTraits'
import Container from '../layout/Container'
import Stack from '../layout/Stack'
import SearchForm from './SearchForm'
import SearchResults from './SearchResults'
import SearchToggle from './SearchToggle'

const SEARCH_QUERY = `
  query Search(
    $search: String!
  ) {
    entries(
      search: $search
    ) {
      id: canonicalId
      title
      sectionHandle
      slug
      postDate
      lft
    }
  }
`

const SESSION_FOR_CHUNKS_QUERY = `
  query SessionsForChunksQuery(
    $chunkIds: [QueryArgument!]!
  ) {
    entries(
      relatedTo: $chunkIds
      section: "sessions"
      orderBy: "lft ASC"
    ) {
      ...on sessions_default_Entry {
        id: canonicalId
        title
        sectionHandle
        slug
        lft
        chunks(
          id: $chunkIds
        ) {
          id: canonicalId
        }
      }
    }
  }
`

const SearchContext = createContext( null )
SearchContext.displayName = 'SearchContext'

export const useSearchContext = () => useContext( SearchContext )

function Search( { modalPortalId, onOpen = null } ) {
  const [searchIsOpen, setSearchIsOpen] = useState( false )

  const toggleSearch = useCallback( 
    ( force = null ) => setSearchIsOpen( s => force === null ? !s : force ),
    [],
  )

  useEffect( () => {
    if ( searchIsOpen && onOpen ) {
      onOpen()
    }
  }, [onOpen, searchIsOpen] )

  useRouteChange( 'routeChangeComplete', () => {
    toggleSearch( false )
  } )

  const contextValue = useMemo(
    () => ( {
      searchIsOpen,
      toggleSearch,
    } ), 
    [searchIsOpen, toggleSearch], 
  )

  return (
    <SearchContext.Provider value={ contextValue }>
      <SearchToggle toggleSearch={ toggleSearch } />
      <SearchModal modalPortalId={ modalPortalId } />
    </SearchContext.Provider>
  )
}

function SearchModal( { modalPortalId } ) {
  const { toggleSearch, searchIsOpen } = useSearchContext()
  const [results, setResults] = useState( null )
  const [search, setSearch] = useState( '' )
  const [isLoading, setIsLoading] = useState( false )
  const [isMounted, setIsMounted] = useState( false )
  const searchModalRef = useRef( null )

  useEffect( () => {
    setIsMounted( true )

    return () => {
      setIsMounted( false )
    }
  }, [] )

  useModalTraits( {
    isOpen: searchIsOpen,
    close: () => toggleSearch( false ),
    containerRef: searchModalRef,
    focusOnOpen: '#search-input',
  } )

  const doSearch = e => {
    e.preventDefault()
    setIsLoading( true )

    request( SEARCH_QUERY, { search } )
      .then( r => r?.data?.entries )
      .then( searchResults => new Promise( resolve => {
        if ( searchResults ) {
          const chunkIds = searchResults
            .filter( entry => entry.sectionHandle === 'chunk' )
            .map( entry => entry.id )

          request( SESSION_FOR_CHUNKS_QUERY, { chunkIds } )
            .then( r2 => r2?.data?.entries )
            .then( sessionsResults => {
              const newResults = sessionsResults
                ? [...searchResults].map( searchResult => ( {
                  ...searchResult,
                  relatedSession: (
                    searchResult.sectionHandle === 'chunks' 
                      && sessionsResults.find( 
                        session => session.chunks.find( chunk => chunk.id === searchResult.id ), 
                      )
                  ),
                } ) )
                : searchResults

              resolve( newResults )
            } )
        }
        else {
          resolve( null )
        }
      } ) )
      .then( newResults => setResults( newResults ) )
      .finally( () => setIsLoading( false ) )
  }

  return isMounted && createPortal(
    <StyledSearch 
      $isOpen={ searchIsOpen }
      ref={ searchModalRef }
    >
      <Container>
        <Stack
          $top={ 6 }
          $between={ 3 }
          $bottom={ 6 }
        >
          <StyledSearchHeader>
            <h1>Search</h1>
            <button
              type="button"
              onClick={ () => toggleSearch( false ) }
            >
              Close
            </button>
          </StyledSearchHeader>
          <SearchForm
            search={ search }
            onChange={ e => setSearch( e.target.value ) }
            onSubmit={ doSearch }
          />
          <SearchResults
            isLoading={ isLoading }
            results={ results }
          />
        </Stack>
      </Container>
    </StyledSearch>,
    document.getElementById( modalPortalId ),
  )
}

interface WithIsOpen {
  $isOpen: boolean,
}

const StyledSearch = styled.div<WithIsOpen>`
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: ${ p => p.theme.colors.ivory };
  pointer-events: none;
  opacity: 0;

  ${ p => p.$isOpen && css`
    pointer-events: all;
    opacity: 1;
  ` }
`

const StyledSearchHeader = styled.div`
  display: flex;

  h1 {
    margin: 0 2rem 0 0;
    ${ p => p.theme.typo.sentientMedium }
    font-size: ${ p => p.theme.typo.sizes.mediumLarge };
    line-height: 1.15;

    @media ( min-width: 550px ) {
      font-size: ${ p => p.theme.typo.sizes.large };
    }

    @media ( min-width: 650px ) {
      font-size: ${ p => p.theme.typo.sizes.hero };
    }
  }

  button {
    position: relative;
    margin-left: auto;
    text-indent: -999rem;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 0;

    &:after {
      content: '✕';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-indent: 0;
    }
  }
`

export default Search
