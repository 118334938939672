import Head from 'next/head'

function MetaTags( { metaTags } ) {
  return (
    <Head>
      { metaTags.map( tag => {
        if ( tag.name === 'title' ) {
          return <title key="title">{ tag.value }</title>
        }

        if ( tag.name === 'description' ) {
          return <meta key="description" name="description" content={ tag.value } />
        }

        return <meta key={ tag.name } property={ tag.name } content={ tag.value } />
      } ) }
    </Head>
  )
}

export default MetaTags
