import ThemeProvider from '../styles/ThemeProvider'
import { CookieConsentProvider } from './CookieConsent'
import { CraftProvider } from '../lib/craft'

function ContextProviders( { pageProps, children } ) {
  return (
    <CraftProvider pageProps={ pageProps }>
      <ThemeProvider>
        <CookieConsentProvider>
          { children }
        </CookieConsentProvider>
      </ThemeProvider>
    </CraftProvider>
  )
}

export default ContextProviders
