import styled from 'styled-components'
import Container from './layout/Container'
import Stack from './layout/Stack'
import { StyledContentGrid } from './MainContent'

function Hero( { ui = null, alignWithMainContent = false, children } ) {
  return (
    <Container>
      <Stack
        $top={ 1 }
        $bottom={ 3 }
        $responsive
      >
        <AlignedHero
          ui={ ui }
          alignWithMainContent={ alignWithMainContent }
        >
          { children }
        </AlignedHero>
      </Stack>
    </Container>
  )
}

function AlignedHero( { ui = null, alignWithMainContent = false, children } ) {
  if ( alignWithMainContent ) {
    return (
      <StyledContentGrid>
        <StyledHero>
          { ui && <div>{ ui }</div> }
          <h1><strong>{ children }</strong></h1>
        </StyledHero>
      </StyledContentGrid>
    )
  }

  return (
    <StyledHero>
      { ui && <div>{ ui }</div> }
      <h1>{ children }</h1>
    </StyledHero>
  )
}

const StyledHero = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  h1 {
    margin: 0;
    max-width: 47rem;
    ${ p => p.theme.typo.sentientLight }
    font-size: ${ p => p.theme.typo.sizes.mediumLarge };
    line-height: 1.15;

    strong {
      display: block;
      font-weight: 500;
    }

    a {
      color: inherit;
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;
      text-decoration-color: ${ p => p.theme.colors.orangeShade };
      transition: text-decoration-color .3s ease-out;

      &:hover {
        text-decoration-color: inherit;
      }
    }

    @media ( min-width: 550px ) {
      font-size: ${ p => p.theme.typo.sizes.large };
    }

    @media ( min-width: 650px ) {
      font-size: ${ p => p.theme.typo.sizes.hero };
    }
  }

  > div b {
    font-weight: 400;
  }

  @media ( max-width: 1155px ) {
    flex-direction: column;
    
    > div {
      margin-bottom: 1rem;
      max-width: 420px;
    }
  }

  @media 
    ( min-width: 1156px ) and ( max-width: 1450px ),
    ( max-width: 500px )
  {
    > div b {
      display: none;
    }
  }

  @media ( min-width: 1156px ) {
    > div {
      margin-left: auto;
    }
  }

  @media ( min-width: 1451px ) {
    > div {
      flex: 0 0 420px;
      min-width: 420px;
    }
  }
`

export default Hero
