import { css } from 'styled-components'

export const fancyLink = css`
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: calc( 100% - 1px);
    left: 0;
    pointer-events: none;
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
    transition: transform 0.3s ease-out;
  }
`

export const fancyLinkHover = css`
  &:before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
  }
`

export const fancyLinkReversed = css`
  ${ fancyLink }

  &:before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
  }
`

export const fancyLinkReversedHover = css`
  &:before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
  }
`
