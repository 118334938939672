import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'
import { CookieConsentBanner } from './CookieConsent'
import MetaTags from './MetaTags'
import MpowerShapes from './MpowerShapes'

function AppChrome( { 
  metaTags = null, 
  footer = true,
  color = 'ivory',
  withShapes = false,
  children, 
} ) {
  return (
    <StyledAppChrome $color={ color }>
      { metaTags && (
        <MetaTags 
        // eslint-disable-next-line react/jsx-props-no-spreading
          { ...metaTags } 
        />
      ) }
      <Header />
      <StyledAppMain>
        { withShapes && (
          <StyledShapes>
            <MpowerShapes /> 
          </StyledShapes>
        ) }
        { children }
      </StyledAppMain>
      { footer && <Footer /> }
      <CookieConsentBanner />
    </StyledAppChrome>
  )
}

const StyledAppChrome = styled.div<{ $color: string }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${ p => p.theme.getColor( p.$color ) };
  cursor: ${ p => p.theme.isLoading ? 'wait' : 'auto' };
`

const StyledAppMain = styled.div`
  position: relative;
  z-index: 0;
  flex: 1;
`

const StyledShapes = styled.div`
  z-index: 0;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  svg {
    position: relative;
    left: 50%;
    display: block;
    min-width: min( 132vw, 1886px );
    max-width: 1886px;
    transform: translateX( -50% );
  }
`

export default AppChrome
