import { useEffect } from 'react'
import { useCraft } from '../lib/craft'
import AppChrome from '../components/AppChrome'
import Hero from '../components/Hero'
import Link from '../components/Link'

function Custom404() {
  const { clientSideRequest } = useCraft()

  useEffect( () => {
    clientSideRequest()
  }, [clientSideRequest] )

  return (
    <AppChrome>
      <Hero>
        <strong>Page not found</strong>
        There isn’t a page at this address. Try the search button, navigation menu, or start at the <Link href="/">homepage</Link>. 
      </Hero>
    </AppChrome>
  )
}

export default Custom404
