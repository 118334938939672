import styled from 'styled-components'
import SearchIcon from '../icons/SearchIcon'

function SearchToggle( { toggleSearch } ) {
  return (
    <StyledSearchToggle
      type="button"
      onClick={ toggleSearch }
    >
      <SearchIcon title="Search the mPower Learn website" />
    </StyledSearchToggle>
  )
}

const StyledSearchToggle = styled.button``

export default SearchToggle
