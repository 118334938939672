import Head from 'next/head'
import Custom404 from './404'
import ContextProviders from '../components/ContextProviders'
import MPowerGAProvider from '../components/MPowerGAProvider'
import '../styles/global.css'
import MPowerMetaTags from '../components/MPowerMetaTags'

function App( { Component, pageProps } ) {
  return (
    <ContextProviders pageProps={ pageProps }>
      <MPowerGAProvider>
        <Head>
          <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
          <link rel="icon" type="image/png" href="/favicon.png" />
        </Head>
        <MPowerMetaTags />
        { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
        { pageProps.status !== 404 && <Component { ...pageProps } /> }
        { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
        { pageProps.status === 404 && <Custom404 { ...pageProps } /> }
      </MPowerGAProvider>
    </ContextProviders>
  )
}

export default App
