import { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import useModalTraits from '../hooks/useModalTraits'
import { fancyLinkReversed, fancyLinkReversedHover } from '../styles/fancyLink'
import Link from './Link'
import BurgerIcon from './icons/BurgerIcon'
import XIcon from './icons/XIcon'
import Search from './search/Search'

export const navItems = [
  {
    href: '/',
    text: 'Home',
  },
  {
    href: '/course',
    text: 'Course',
  },
  {
    href: '/about',
    text: 'About',
  },
  {
    href: '/resources',
    text: 'Resources',
  },
  {
    href: '/contact',
    text: 'Contact',
  },
]

const breakpoint = 900

function Nav() {
  const navRef = useRef( null )
  const navPanelRef = useRef( null )
  const [isOpen, setIsOpen] = useState( false )
  
  useModalTraits( {
    isOpen,
    close: () => setIsOpen( false ),
    containerRef: navPanelRef,
    focusableElementsSelector: 'a, button',
  } )

  return (
    <StyledNav 
      ref={ navRef }
      $isOpen={ isOpen }
    >
      <StyledNavToggle>
        <button
          type="button"
          onClick={ () => setIsOpen( s => !s ) }
        >
          <BurgerIcon color="purple" />
          <span>Menu</span>
        </button>
      </StyledNavToggle>
      <StyledNavPanel ref={ navPanelRef }>
        <StyledCloseButton
          type="button"
          onClick={ () => setIsOpen( false ) }
        >
          <XIcon color="black" />
        </StyledCloseButton>
        <StyledNavList>
          { navItems.map( navItem => (
            <StyledNavListItem 
              key={ navItem.href }
              $isActive={ false }
              onClick={ () => setIsOpen( false ) }
            >
              <Link href={ navItem.href }>
                { navItem.text }
              </Link>
            </StyledNavListItem>
          ) ) }
          <StyledSearchToggle>
            <Search 
              modalPortalId="mp-search-modal" 
              onOpen={ () => setIsOpen( false ) }
            />
          </StyledSearchToggle>
        </StyledNavList>
      </StyledNavPanel>
    </StyledNav>
  )
}

interface WithIsOpen {
  $isOpen: boolean,
}

const StyledNavPanel = styled.div`
  flex-grow: 1;
`

const StyledNav = styled.div<WithIsOpen>`
  display: flex;

  @media ( max-width: ${ breakpoint - 1 }px ) {
    position: fixed;
    top: 0;
    right: 0;

    ${ StyledNavPanel } {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: ${ p => p.theme.colors.black };
      color: ${ p => p.theme.colors.ivory };
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s ease-out;

      ${ p => p.$isOpen && css`
        opacity: 1;
        pointer-events: all;
      ` }
    }
  }
`

const toggleButtonPosition = css`
  position: absolute;
  top: 1rem;
  right: 6.5vw;
  transform: translateX( 7px );
`

const StyledNavToggle = styled.div`
  ${ toggleButtonPosition }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    padding: 0;
    border-radius: 100%;
    background: ${ p => p.theme.colors.ivory };
    color: ${ p => p.theme.colors.black };
    border: none;

    span {
      display: block;
      position: absolute;
      left: -1000rem;
    }

    svg {
      display: block;
      
      line {
        stroke: currentColor;
      }
    }
  }

  @media ( min-width: ${ breakpoint }px ) {
    display: none;
  }
`

const StyledCloseButton = styled.button`
  ${ toggleButtonPosition }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: transparent;
  color: ${ p => p.theme.colors.white };
  border: 0;
  transition: background .3s ease-out;

  svg line {
    stroke: currentColor;
  }

  &:hover {
    background: ${ p => p.theme.colors.grey5 };
  }

  @media ( min-width: ${ breakpoint }px ) {
    display: none;
  }
`

const StyledNavList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 -1rem;

  @media ( max-width: ${ breakpoint - 1 }px ) {
    flex-grow: 1;
    flex-direction: column;
  }

  @media ( min-width: ${ breakpoint }px ) {}
`

interface StyledNavListItem {
  $large?: boolean,
}

const StyledNavListItem = styled.li<{ $isActive?: boolean }>`
  padding: 0 1rem;

  > a,
  > button {
    position: relative;
    display: block;
    ${ p => p.theme.typo.switzer };
    text-decoration: none;
    color: inherit;
    background: transparent;
    border: 0;
    ${ fancyLinkReversed }

    &:hover {
      ${ fancyLinkReversedHover }
    }
  }

  ${ p => p.$isActive && css`
    font-weight: 500;
  ` }

  @media ( max-width: ${ breakpoint - 1 }px ) {
    > a,
    > button {
      padding: 0.5rem 0;
      font-size: ${ p => p.theme.typo.sizes.bumped };
    }
  }

  @media ( min-width: ${ breakpoint }px ) {
    > a,
    > button {
      padding: 2.8rem 0 0.1rem;
    }
  }
`

const StyledSearchToggle = styled( StyledNavListItem )`
  @media ( max-width: ${ breakpoint - 1 }px ) {
    svg {
      path {
        stroke: ${ p => p.theme.colors.white };
      } 
    }
  }

  @media ( min-width: ${ breakpoint }px ) {
    margin-left: auto;
  }
`

export default Nav
