import type { ImageProps } from 'next/image'
import NextImage from 'next/image'

const ORIGIN = process.env.NEXT_PUBLIC_CLOUDFRONT_URL
const HANDLER = process.env.NEXT_PUBLIC_IMAGE_HANDLER_URL
const BUCKET_PREFIX = process.env.NEXT_PUBLIC_CLOUDFRONT_PATH_PREFIX
const BUCKET = process.env.NEXT_PUBLIC_S3_BUCKET

const isValidUrl = url => [
  ORIGIN,
].some( vu => url.startsWith( vu ) )

const universalBtoa = payload => {
  if ( typeof btoa !== 'undefined' ) {
    return btoa( payload )
  }

  return Buffer.from( payload, 'utf8' ).toString( 'base64' )
}

const imageLoader = ( preResizeEdits, dateModified ) => ( { src, width } ) => {
  if ( !isValidUrl( src ) ) {
    return src
  }

  if ( src.endsWith( '.svg' ) ) {
    return src
  }

  return imageUrl( {
    src, width, preResizeEdits, dateModified, 
  } )
}

type Edits = {
  extract?: {
    top: number,
    left: number,
    width: number,
    height: number,
  },
}

interface ImageUrlCommon {
  src: string,
  fit?: string,
  focalPoint?: {
    x: number,
    y: number,
  }
  preResizeEdits?: Edits,
  dateModified?: string,
  width?: number,
  height?: number,
}

interface ImageUrlWithWidth extends ImageUrlCommon {
  width: number,
}

interface ImageUrlWithHeight extends ImageUrlCommon {
  height: number,
}

interface ImageUrlWithEdits extends ImageUrlCommon {
  edits: Edits,
}

type ImageUrl =
  ImageUrlWithWidth
  | ImageUrlWithHeight
  | ( ImageUrlWithWidth & ImageUrlWithHeight )
  | ImageUrlWithEdits

export const imageUrl = ( {
  src,
  preResizeEdits = null,
  dateModified = null,
  width = null,
  height = null,
  fit = 'inside',
  focalPoint = null,
}: ImageUrl ) => {
  if ( !isValidUrl( src ) ) {
    throw new Error( 'The image src URL must begin with the Cloufront URL associated with the S3 bucket for this image processor.' )
  }

  const edits = ( preResizeEdits ? { ...preResizeEdits } : {} ) as any

  edits.resize = {
    width,
    height,
    options: {
      fit,
    },
  }

  if ( focalPoint ) {
    // TODO calc coords for extracting the crop
    // edits.extract = {
    //   left,
    //   top,
    //   right,
    //   bottom,
    // }
  }

  const imageRequest = JSON.stringify( {
    bucket: BUCKET,
    key: src.replace( `${ ORIGIN }/`, `${ BUCKET_PREFIX.replace( '/', '' ) }/` ),
    edits,
    dateModified,
  } )

  const encodedRequest = universalBtoa( imageRequest )
  return `${ HANDLER }/${ encodedRequest }`
}

type ImageWithLoaderProps = ImageProps & {
  preResizeEdits?: {
    [key: string]: any,
  },
  dateModified?: string,
}

function Image( {
  preResizeEdits = null,
  dateModified = null,
  ...props
}: ImageWithLoaderProps ) {
  return (
    <NextImage
      loader={ imageLoader( preResizeEdits, dateModified ) }
      sizes="100vw"
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...props }
      alt={ props.alt || '' }
    />
  )
}

export default Image
