import type { ColorKeys } from '../../styles/colors'
import { getColor } from '../../styles/colors'

function BurgerIcon( { color = null }: { color?: ColorKeys } ) {
  const c = getColor( color ) || 'grey8'

  return (
    <svg 
      width="29"
      height="17"
      viewBox="0 0 29 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="0.5" x2="29" y2="0.5" stroke={ c } />
      <line y1="8.5" x2="29" y2="8.5" stroke={ c } />
      <line y1="16.5" x2="29" y2="16.5" stroke={ c } />
    </svg>
  )
}

export default BurgerIcon
