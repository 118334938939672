import styled from 'styled-components'
import { rgba } from 'polished'
import { useCraft } from '../lib/craft'
import { layoutVariables } from '../styles/layoutVariables'
import Container from './layout/Container'
import Stack from './layout/Stack'
import ContentBlocks from './ContentBlocks'
import SideNav from './SideNav'
import Link from './Link'
import Chevron from './icons/Chevron'
import SessionIllustration from './SessonIllustration'

const getNextPage = ( sessions, currentSession, currentChunk ) => {
  if ( !currentSession ) {
    return null
  }
  
  const currentChunkIndex = currentSession?.chunks.findIndex( c => currentChunk?.id === c.id )
  const nextChunkIndex = currentChunkIndex === -1 ? 0 : currentChunkIndex + 1

  if ( currentSession?.chunks[ nextChunkIndex ] ) {
    const nextChunk = currentSession?.chunks[ nextChunkIndex ]

    return (
      <Link href={ `/course/chunks/${ nextChunk.slug }?sessionId=${ currentSession.id }` }>
        { currentChunk ? 'Next' : 'Get started' }:
        <span>
          <b>
            { nextChunkIndex + 1 }.&nbsp;
            { nextChunk.chunkType.length > 0 && nextChunk.chunkType[ 0 ].title }:&nbsp;
            { nextChunk.title }
          </b>
          <Chevron right />
        </span>
      </Link>
    )
  }

  const currentSessionIndex = sessions?.findIndex( s => currentSession?.id === s.id )
  const nextSessionIndex = currentSessionIndex === -1 ? 0 : currentSessionIndex + 1

  if ( sessions[ nextSessionIndex ] ) {
    const nextSession = sessions[ nextSessionIndex ]

    return (
      <Link href={ `/course/${ nextSession.slug }` }>
        Next Session:
        <span>
          <b>
            { nextSession.title }
          </b>
          <Chevron right />
        </span>
      </Link>
    )
  }

  return null
}

function MainContent( { 
  contentBlocks, 
  session = null,
  currentChunk = null,
} ) {
  const craftData = useCraft()
  const nextPage = getNextPage( craftData?.pageProps?.sessions, session, currentChunk )

  return (
    <Container>
      <Stack $bottom={ 4 }>
        <StyledContentGrid>
          { session && !currentChunk && session.sessionIcon.length > 0 && (
            <SessionIllustration
              image={ session.sessionIcon[ 0 ] }
            />
          ) }
          { session && currentChunk && (
            <SideNav
              session={ session }
              currentChunk={ currentChunk }
            />
          ) }
          <div>
            <ContentBlocks contentBlocks={ contentBlocks } />
            { nextPage && (
              <StyledNextLink>
                { nextPage }
              </StyledNextLink>
            ) }
          </div>
        </StyledContentGrid>
      </Stack>
    </Container>
  )
}

export const StyledContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat( 12, calc( 100% / 12 ) );
  margin: 0 calc( var( --grid-gutter ) / -2 );

  > * {
    margin: 0 calc( var( --grid-gutter ) / 2 );
    grid-column: 1 / 13;
    max-width: 47rem;
    
    @media ( min-width: ${ layoutVariables.sideNavBreakpoint }px ) {
      &:nth-child( 2 ),
      &:only-child {
        grid-column: 5 / 13;
      }

      &:first-child:not( :only-child ) {
        grid-column: 1 / 4;
      }
    }
  }
`

const StyledNextLink = styled.div`
  margin-top: 2rem;

  a {
    display: inline-block;
    max-width: 25rem;
    padding: 1rem;
    color: inherit;
    text-decoration: none;
    border: 1px solid ${ p => rgba( p.theme.colors.black, 0.3 ) };
    border-radius: 20px;
    transition: 
      background-color .3s ease-out,
      color .3s ease-out;

    span,
    b {
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    svg {
      margin-left: 1rem;

      path {
        stroke: currentColor;
      }
    }

    &:hover {
      background: ${ p => p.theme.colors.black };
      color: ${ p => p.theme.colors.white };
    }
  }
`

export default MainContent
